import {useSelector} from "react-redux";
import {selectCurrentStudent} from "../main/mainSlice";
import {Flex, FlexItem} from "react-weui";
import {truncateString} from "../../utils/util";

export function StudentInfo() {
  const currentStudent = useSelector(selectCurrentStudent)
  return currentStudent && <div style={{padding: '10px 20px', marginBottom: -40}}>
    <Flex>
      <FlexItem style={{flex: 'none', marginLeft: 0}}>
        <span className="hint">学生</span>
      </FlexItem>
      <FlexItem style={{flex: 'none', marginLeft: 0}}>
        <div style={{marginLeft: 8, fontSize: 14, display: 'inline'}}>{currentStudent.nickname}<br />({currentStudent.name})</div>
      </FlexItem>
      <FlexItem style={{flex: 'none', marginLeft: 'auto'}}>
        <span className="hint">组别</span>
        <span style={{marginLeft: 8, fontSize: 14}}>{truncateString(currentStudent.groups.join(','),15)}</span>
      </FlexItem>
    </Flex>
  </div>
}

import {selectCurrentStudent} from "../main/mainSlice";
import {LoadMore, Flex, FlexItem} from "react-weui";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchContestList,
  selectContestList,
  selectFetchContestListError,
  selectLastType,
  selectLoadComplete
} from "./contestsSlice";
import {selectToken} from "../../app/authSlice";
import {Error} from "../../components/Error";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {setContest} from "../contest/contestSlice";
import {store} from "../../app/store";
import {getContestStatus} from "../../utils/contest";

const lazyloadHandler = (e) => {
  if (e.clientY >= document.body.clientHeight - 50) return
  const state = store.getState()
  const complete = selectLoadComplete(state)
  if (complete) return
  if (state.contests.loading) return
  const top = document.getElementById('loadmore')?.getBoundingClientRect()?.top || 0
  if (top < document.body.clientHeight + 300) {
    store.dispatch(fetchContestList({token: selectToken(state), userId: selectCurrentStudent(state).id, type: selectLastType(state)}))
  }
}

export function Contests(props) {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectCurrentStudent);
  const contestList = useSelector(selectContestList);
  const fetchContestListError = useSelector(selectFetchContestListError);
  const loadComplete = useSelector(selectLoadComplete);
  const lastType = useSelector(selectLastType);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) return
    if (lastType === props.type && loadComplete) return
    dispatch(fetchContestList({token, userId: user.id, type: props.type}));
  }, [props.type, dispatch, user, token, lastType, loadComplete])
  useEffect(() => {
    document.addEventListener('touchstart', lazyloadHandler)
    document.addEventListener('scroll', lazyloadHandler)
    document.addEventListener('mousedown', lazyloadHandler)
    return () => {
      document.removeEventListener('touchstart', lazyloadHandler)
      document.removeEventListener('scroll', lazyloadHandler)
      document.removeEventListener('mousedown', lazyloadHandler)
    }
  }, [])
  return (<>
    {!contestList && !fetchContestListError && <div className="fullscreen">
      <LoadMore loading>加载中</LoadMore>
    </div>}
    <Error error={fetchContestListError} />
    {contestList && <>
      <div className="page-header">
        <div className="page-title">
          课堂列表
        </div>
      </div>
      <div className="page-body">
        {contestList.map(contest => {
          return <div className="menu-item" key={contest._id} onClick={() => {
            if (Date.now() < contest.startedAt) {
              alert("比赛还未开始")
              return
            }
            dispatch(setContest(contest))
            navigate(`/contests/${contest._id}`)
          }}>
            {contest.type !== "targetedtraining" && <p style={{position: 'absolute', top: 8, right: 12, color: 'grey', fontSize: 12}}>{getContestStatus(contest.startedAt, contest.endedAt)}</p>}
            {contest.type !== "targetedtraining" && <p style={{position: 'absolute', bottom: 8, right: 12, color: 'grey', fontSize: 12}}>{moment(contest.startedAt).format('Y-M-D H:mm')}</p>}
            <div style={{width: '100%'}}>
              <p>{contest.contestTitle}</p>
              <Flex style={{width: '100%'}}>
                {/* {props.type === "contest" && <>
                <FlexItem>
                  <span className="hint">比赛结果</span>
                  <span className="number">{contest.contestRank.totalScore || 0}/{contest.problems.length * 100}</span>
                </FlexItem>
                {contest.totalAfterScore != null && <FlexItem>
                  <span className="hint">改错情况</span>
                  <span className="number">{contest.contestRank.totalAfterScore}</span>
                </FlexItem>}
                </>} */}
                {props.type === "teachercourse" && <FlexItem>
                  <span className="hint">完成情况</span>
                  <span className="number">{contest.contestRank.totalAfterScore || 0}/{contest.problems.length * 100}</span>
                </FlexItem>}
              </Flex>
            </div>
          </div>
        })}
        {loadComplete && <LoadMore id="loadmore" showLine>没有更多了</LoadMore>}
        {!loadComplete && <LoadMore loading>加载中</LoadMore>}
      </div>
    </>}
  </>)
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAxios} from "../../utils/api";

const initialState = {
  currentStudent: null,
  studentList: null,
  fetchStudentListError: null,
};

export const fetchStudentList = createAsyncThunk(
  'main/fetchStudentList',
  async (token, thunkAPI) => {
    const axios = getAxios(token)
    try {
      const res = await axios.get('/api/students')
      return res.data
    } catch(e) {
      if (e.response && e.response.data) throw e.response.data
      throw e
    }
  }
)

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setCurrentStudent: (state, action) => {
      state.currentStudent = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchStudentList.fulfilled, (state, action) => {
      state.studentList = action.payload
    })
    builder.addCase(fetchStudentList.rejected, (state, action) => {
      state.fetchStudentListError = action.error
    })
  }
});

export const { setCurrentStudent } = mainSlice.actions;

export const selectCurrentStudent = (state) => state.main.currentStudent;
export const selectStudentList = (state) => state.main.studentList;
export const selectFetchStudentListError = (state) => state.main.fetchStudentListError;

export default mainSlice.reducer;

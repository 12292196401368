import React, {useEffect} from 'react';
import { Login } from './features/login/Login';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectToken, setToken} from "./app/authSlice";
import {BindStudent} from "./features/bindStudent/BindStudent";
import {Main} from "./features/main/Main";
import {Contest} from "./features/contest/Contest";
import {Submission} from "./features/submission/Submission";
import {Contests} from "./features/contests/Contests";

function isJWTValid(jwt) {
  try {
    const payload = JSON.parse(atob(jwt.split('.')[1]))
    return payload.exp > Date.now() / 1000 + 3600
  } catch {
    return false
  }
}

function App() {
  const token = useSelector(selectToken)
  const dispatch = useDispatch()
  useEffect(() => {
    if (token === null && window.location.pathname !== "/callback") {
      if (localStorage.getItem("token")) {
        if (isJWTValid(localStorage.getItem("token"))) {
          dispatch(setToken(localStorage.getItem("token")))
          return
        } else {
          localStorage.setItem("token", null)
        }
      }
      // window.location.replace("https://wx.hjjoi.com/callback?code=123&state=" + window.location.pathname + window.location.search)
      window.location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc29ab6d7dd1d42cd&redirect_uri=https%3A%2F%2Fwx.hjjoi.com%2Fcallback&response_type=code&scope=snsapi_base&state=" + window.location.pathname + window.location.search + "#wechat_redirect")
    }
  }, [dispatch, token])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/bindStudent" element={<BindStudent />} />
        <Route path="/callback" element={<Login />} />
        <Route path="/contests/:contestId" element={<Contest />} />
        <Route path="/submissions/:submissionId" element={<Submission />} />
        <Route path="/" element={<Main />}>
          {/* <Route path="contests" element={<Contests type="contest" />} /> */}
          {/* <Route path="targetedtraining" element={<Contests type="targetedtraining" />} /> */}
          <Route path="teachercourse" element={<Contests type="teachercourse" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import {selectCurrentStudent} from "../main/mainSlice";
import {Flex, FlexItem, Page, CellsTitle} from "react-weui";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../../app/authSlice";
import {useNavigate} from "react-router-dom";
import {fetchContestRank, selectContest, selectContestRank} from "./contestSlice";
import {InfoCard} from "../../components/InfoCard";
import {setProblem} from "../submission/submissionSlice";
import {getContestStatus} from "../../utils/contest";
import moment from "moment";
import {StudentInfo} from "../studentInfo/StudentInfo";

export function Contest() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectCurrentStudent);
  const contest = useSelector(selectContest);
  const contestRank = useSelector(selectContestRank);
  // const fetchContestRankError = useSelector(selectFetchContestRankError);
  const navigate = useNavigate();
  const [score, setScore] = useState(null);
  useEffect(() => {
    if (!user) return
    if (!contest) return
    dispatch(fetchContestRank({token, userId: user.id, contestId: contest._id}));
  }, [dispatch, user, contest, token])
  useEffect(() => {
    if (!contestRank) return
    const afterScoreMap = new Map();
    const realScoreMap = new Map();
    contestRank.studentRecord?.scores?.forEach(score => {
      if (score.type === "real") {
        realScoreMap.set(score.problemId, score.score);
      } else if (score.type === "after") {
        afterScoreMap.set(score.problemId, score.score);
      }
    })
    setScore({
      afterScoreMap,
      realScoreMap
    });
  }, [contestRank])
  return (<>
    {contest && <Page ptr={false} infiniteLoader={false}>
      <StudentInfo />
      <div className="page-header">
        <div className="page-title">
          课堂详情
        </div>
        <div className="page-subtitle">
          {contest.contestTitle}
        </div>
      </div>
      <div className="page-body" style={{gap: 0}}>
        <InfoCard data={[
          {title: "课堂状态", value: getContestStatus(contest.startedAt, contest.endedAt)},
          {title: "开始时间", value: moment(contest.startedAt).format('YYYY-MM-DD HH:mm')},
          {title: "结束时间", value: moment(contest.endedAt).format('YYYY-MM-DD HH:mm')},
          contest.contestRank.totalScore != null ? {title: '比赛结果', value: `${contest.contestRank?.totalScore}/${contest.problems.length*100}`} : null,
          contest.contestRank.totalScore != null ? {title: '比赛排名', value: contestRank ? `${contestRank?.rank || 0}/${contestRank.totalStudent}` : '加载中'} : null,
          contest.contestRank.totalAfterScore != null ? {title: '改错情况', value: `${contest.contestRank.totalAfterScore}/${contest.problems.length*100}`} : null,
          contest.contestRank.totalAfterScore != null ? {title: '改错排名', value: contestRank ? `${contestRank.afterRank}/${contestRank.totalStudent}` : '加载中'} : null,
        ]}/>
        <p className="hint" style={{margin: 8}}>点击下方卡片中 `比赛结果` 或 `改错情况` 可查看各题目详情</p>
        <CellsTitle style={{marginTop: 0}}>题目列表</CellsTitle>
        <div className="spacing" style={{display: 'flex', flexDirection: 'column'}}>
        {contest.problems.map(problem => <div className="card" key={problem.problemId}>
          <div style={{width: '100%'}}>
            <p>{problem.displayId} - {problem.problemTitle}</p>
            <Flex style={{width: '100%'}}>
              {score && score.realScoreMap.has(problem.problemId) && <FlexItem onClick={()=> {
                const scoreRecord = contestRank.studentRecord.scores.find(score => score.problemId === problem.problemId && score.type === "real")
                dispatch(setProblem({
                  score: scoreRecord,
                  problem
                }))
                navigate('/submissions/'+scoreRecord.submissionId)
              }}>
                <span className="hint">比赛结果</span>
                <span className="number">{score.realScoreMap.get(problem.problemId)}</span>
              </FlexItem>}
              {score && score.afterScoreMap.has(problem.problemId) &&  <FlexItem onClick={()=> {
                const scoreRecord = contestRank.studentRecord.scores.find(score => score.problemId === problem.problemId && score.type === "after")
                dispatch(setProblem({
                  score: scoreRecord,
                  problem
                }))
                navigate('/submissions/'+scoreRecord.submissionId)
              }}>
                <span className="hint">改错情况</span>
                <span className="number">{score.afterScoreMap.get(problem.problemId)}</span>
              </FlexItem>}
            </Flex>
          </div>
        </div>)}
        </div>
      </div>
    </Page>}
  </>)
}

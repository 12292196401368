import { LoadMore } from 'react-weui'
import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setToken} from "../../app/authSlice";
import {Error} from "../../components/Error";

export function Login() {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    axios.post("/api/login", {
      code: params.get("code")
    }).then(res => {
      dispatch(setToken(res.data))
      localStorage.setItem("token", res.data)
      navigate(params.get("state"), {replace: true})
    }).catch(setError)
  }, [dispatch, navigate, params])
  return (
    <div className="fullscreen">
      {!error && <LoadMore loading>加载中</LoadMore>}
      <Error error={error} />
    </div>
  )
}

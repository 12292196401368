export function InfoCard(props) {
  return (
    <div className="card spacing" style={{flexDirection: 'column'}}>
      {props.data.map(item => {
        if (!item) return null;
        return <div key={item.title} style={{width: '100%'}}>
          <span className="number" style={{float: 'right'}}>{item.value}</span>
          <span>{item.title}</span>
        </div>
      })}
    </div>
  )
}

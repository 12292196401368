import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAxios} from "../../utils/api";

const initialState = {
  contestRank: null,
  fetchContestRankError: null,
  contest: null,
};

export const fetchContestRank = createAsyncThunk(
  'contests/fetchContestRank',
  async ({token, userId, contestId}, thunkAPI) => {
    const axios = getAxios(token)
    try {
      const res = await axios.get('/api/students/'+userId+'/contests/'+contestId)
      return res.data
    } catch(e) {
      if (e.response && e.response.data) throw e.response.data
      throw e
    }
  }
)

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setContest: (state, action) => {
      state.contest = action.payload
      state.contestRank = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchContestRank.fulfilled, (state, action) => {
      state.contestRank = action.payload
    })
    builder.addCase(fetchContestRank.rejected, (state, action) => {
      state.fetchContestRankError = action.error
    })
  }
});

export const { setContest } = contestSlice.actions;

export const selectContest = (state) => state.contest.contest;
export const selectContestRank = (state) => state.contest.contestRank;
export const selectFetchContestRankError = (state) => state.contest.fetchContestRankError;

export default contestSlice.reducer;

import {useDispatch, useSelector} from "react-redux";
import {
  fetchStudentList,
  selectCurrentStudent,
  selectFetchStudentListError,
  selectStudentList,
  setCurrentStudent
} from "./mainSlice";
import {useEffect} from "react";
import {selectToken} from "../../app/authSlice";
import {Page, LoadMore, TabBarItem, TabBar, TabBody} from "react-weui";
import 'weui-icon/dist/filled/arrow.css';
import {FaCalendar} from "react-icons/fa";
import {Error} from "../../components/Error";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {StudentInfo} from "../studentInfo/StudentInfo";

export function Main() {
  const studentList = useSelector(selectStudentList);
  const currentStudent = useSelector(selectCurrentStudent);
  const token = useSelector(selectToken);
  const fetchStudentListError = useSelector(selectFetchStudentListError);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/teachercourse", {replace: true});
    }
  }, [navigate, location])
  useEffect(() => {
    if (!token) return
    dispatch(fetchStudentList(token));
  }, [dispatch, token])
  useEffect(() => {
    if (studentList === null) {
      return
    }
    if (studentList.length === 1) {
      dispatch(setCurrentStudent(studentList[0]));
    }
  }, [dispatch, studentList])
  return (
    <>
      {!studentList && !fetchStudentListError && <div className="fullscreen">
        <LoadMore loading>加载中</LoadMore>
      </div>}
      <Error error={fetchStudentListError} />
      {studentList && !currentStudent &&
        <Page>
          <div className="page-header">
            <div className="page-title">
              选择学生
            </div>
          </div>
          <div className="page-body">
            {studentList.map(student => <div className="menu-item" key={student.id} onClick={() => {
              dispatch(setCurrentStudent(student))
            }}>
              {`${student.nickname}(@${student.name})`}
            </div>)}
          </div>
        </Page>
      }
      {currentStudent && <Page ptr={false} infiniteLoader={false}>
        <div className="weui-tab">
          <TabBody>
            <StudentInfo />
            <Outlet />
          </TabBody>
          <TabBar>
          <TabBarItem
            active={location.pathname === "/teachercourse"}
            onClick={() => {
              navigate("/teachercourse", {replace: true});
            }}
            label="课堂情况" icon={<i style={{width: 24, height: 24}}><FaCalendar /></i>}>
          </TabBarItem>
          {/* <TabBarItem
            active={location.pathname === "/targetedtraining"}
            onClick={() => {
              navigate("/targetedtraining", {replace: true});
            }}
            label="作业情况" icon={<i style={{width: 24, height: 24}}><FaBookmark /></i>}>
          </TabBarItem> */}
          </TabBar>
        </div>
      </Page>}
    </>
  )
}

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import contestReducer from '../features/contest/contestSlice'
import contestsReducer from '../features/contests/contestsSlice'
import mainReducer from '../features/main/mainSlice'
import submissionReducer from '../features/submission/submissionSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contest: contestReducer,
    contests: contestsReducer,
    main: mainReducer,
    submission: submissionReducer,
  },
});

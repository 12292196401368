import {useSelector} from "react-redux";
import {selectToken} from "../../app/authSlice";
import {useEffect, useState} from "react";
import {getAxios} from "../../utils/api";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Error} from "../../components/Error";
import {Page, Msg, LoadMore} from "react-weui";
import 'weui-icon/dist/filled/info.css';
import 'weui-icon/dist/filled/done2.css';


export function BindStudent() {
  const token = useSelector(selectToken)
  const [search] = useSearchParams()
  const [student, setStudent] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (token === null) {
      return
    }
    getAxios(token).get("/api/bindSession/" + search.get("id"))
      .then(res => setStudent(res.data))
      .catch(setError)
  },[search, token])
  return (<>
    {!student &&
    <div className="fullscreen">
      {!error && !student && <LoadMore loading>加载中</LoadMore>}
      <Error error={error} />
    </div>}
    {student && !succeeded && !canceled &&
      <Page title="绑定学生">
        <Msg
          type="filled-info"
          title="绑定学生"
          description={`确认要绑定学生 ${student.nickname}(@${student.name}) 吗？`}
          buttons={[{
            type: 'primary',
            label: '确认',
            disabled: loading,
            onClick: () => {
              setLoading(true)
              getAxios(token).post("/api/bindSession/" + search.get("id"))
                .then(() => {
                  setSucceeded(true)
                  setLoading(false)
                })
                .catch(err => {
                  setError(err)
                  setStudent(null)
                })
            }
          }, {
            type: 'default',
            label: '取消',
            onClick: () => {
              setCanceled(true)
            }
          }]}
        />
      </Page>
    }
    {succeeded &&
      <Page title="绑定学生">
        <Msg
          type="filled-done2"
          title="绑定成功"
          buttons={[{
            type: 'primary',
            label: '前往主页',
            disabled: loading,
            onClick: () => {
              navigate('/', {replace: true})
            }
          }]}
        />
      </Page>
    }
    {canceled &&
      <Page title="绑定学生">
        <Msg
          type="filled-info"
          title="已取消绑定"
        />
      </Page>
    }
  </>)
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAxios} from "../../utils/api";

const initialState = {
  contestList: null,
  contestPage: 0,
  contestTotal: 20,
  lastType: '',
  loading: false,
  fetchContestListError: null,
};

export const fetchContestList = createAsyncThunk(
  'contests/fetchContestList',
  async ({token, userId, type}, thunkAPI) => {
    console.log(type)
    const state = thunkAPI.getState();
    let page = state.contests.contestPage + 1
    if (state.contests.lastType !== type) {
      page = 1
    }
    const axios = getAxios(token)
    try {
      const res = await axios.get('/api/students/'+userId+'/contests?type='+type+'&page='+page)
      return {
        type,
        ...res.data,
      }
    } catch(e) {
      if (e.response && e.response.data) throw e.response.data
      throw e
    }
  }
)

export const contestsSlice = createSlice({
  name: 'contests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchContestList.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(fetchContestList.fulfilled, (state, action) => {
      if (action.payload.page === 1) {
        state.contestList = action.payload.rows
      } else {
        state.contestList = [
          ...state.contestList,
          ...action.payload.rows
        ]
      }
      state.contestPage = action.payload.page
      state.contestTotal = action.payload.total
      state.lastType = action.payload.type
      state.loading = false
    })
    builder.addCase(fetchContestList.rejected, (state, action) => {
      state.fetchContestListError = action.error
      state.loading = false
    })
  }
});

export const selectContestList = (state) => state.contests.contestList;
export const selectFetchContestListError = (state) => state.contests.fetchContestListError;
export const selectLastType = (state) => state.contests.lastType;
export const selectLoadComplete = (state) => state.contests.contestList && state.contests.contestTotal === state.contests.contestList.length;

export default contestsSlice.reducer;

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAxios} from "../../utils/api";

const initialState = {
  score: null,
  problem: null,
  submission: null,
  fetchSubmissionError: null,
};

export const fetchSubmission = createAsyncThunk(
  'submission/fetchSubmission',
  async ({token, submissionId}, thunkAPI) => {
    const axios = getAxios(token)
    try {
      const res = await axios.get('/api/submissions/'+submissionId)
      return res.data
    } catch(e) {
      if (e.response && e.response.data) throw e.response.data
      throw e
    }
  }
)

export const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setProblem: (state, action) => {
      state.score = action.payload.score
      state.problem = action.payload.problem
      state.submission = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSubmission.fulfilled, (state, action) => {
      state.submission = action.payload
    })
    builder.addCase(fetchSubmission.rejected, (state, action) => {
      state.fetchSubmissionError = action.error
    })
  }
});

export const {setProblem} = submissionSlice.actions;

export const selectScore = (state) => state.submission.score;
export const selectProblem = (state) => state.submission.problem;
export const selectSubmission = (state) => state.submission.submission;
export const selectFetchSubmissionError = (state) => state.submission.fetchSubmissionError;

export default submissionSlice.reducer;

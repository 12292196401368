import {selectCurrentStudent} from "../main/mainSlice";
import {Page, CellsTitle} from "react-weui";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../../app/authSlice";
import moment from "moment";
import {InfoCard} from "../../components/InfoCard";
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
  fetchSubmission,
  // selectFetchSubmissionError,
  selectProblem,
  selectScore,
  selectSubmission
} from "./submissionSlice";
import {StudentInfo} from "../studentInfo/StudentInfo";

const statusMap = {
  "CompilationError": "编译错误",
  "RuntimeError": "运行时错误",
  "TimeLimitExceeded": "运行时间超限",
  "MemoryLimitExceeded": "内存超限",
  "OutputLimitExceeded": "输出超限",
  "Accepted": "通过",
  "WrongAnswer": "答案错误",
  "JudgementFailed": "评测错误",
  "Wrong Answer": "答案错误",
  "WRONG_ANSWER": "答案错误",
  "TIME_LIMIT_EXCEEDED": "运行时间超限",
  "Time Limit Exceeded": "运行时间超限",
  "MEMORY_LIMIT_EXCEEDED": "内存超限",
  "Memory Limit Exceeded": "内存超限",
  "OK": "通过",
  "RUNTIME_ERROR": "运行时错误",
  "Runtime Error": "运行时错误",
  "COMPILATION_ERROR": "编译错误",
  "Compilation Error": "编译错误",
}

const colorMap = {
  "编译错误": "#ff54a7",
  "运行时错误": "#ff00ff",
  "运行时间超限": "#3f91f7",
  "内存超限": "#ff00ff",
  "输出超限": "#ff00ff",
  "通过": "#11c211",
  "答案错误": "#ff0000",
  "评测错误": "#7f7f7f",
  "其他错误": "#7f7f7f",
}

ChartJS.register(ArcElement, Tooltip, Legend);

export function Submission() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectCurrentStudent);
  const score = useSelector(selectScore);
  const problem = useSelector(selectProblem);
  const submission = useSelector(selectSubmission);
  // const fetchSubmissionError = useSelector(selectFetchSubmissionError);
  const [statusCount, setStatusCount] = useState(null);
  useEffect(() => {
    if (!user) return
    if (!score) return
    dispatch(fetchSubmission({token, submissionId: score.submissionId}));
  }, [dispatch, user, token, score])
  const getProperty = (tc, key) => {
    if (tc[key] !== undefined) {
        return tc[key]
    }
    return tc[key.substr(0,1).toUpperCase() + key.substr(1)]
  }
  useEffect(() => {
    if(!submission) return
    try {
      console.log(submission)
      if (submission.judgeType === 'VJudge') {
        const result = JSON.parse(submission.judgeExtraResultJson)
        const statusCount = {};
        result.testCases.forEach(testcase => {
          const verdict = statusMap[getProperty(testcase, 'verdict')] || "其他错误"
          if (!statusCount[verdict]) statusCount[verdict] = 0
          statusCount[verdict]++;
        })
        setStatusCount(statusCount);
      } else {
        const result = JSON.parse(submission.judgeExtraResultJson)
        const statusCount = {};
        Object.values(result.testcaseResult).forEach(testcase => {
          const verdict = statusMap[getProperty(testcase, 'status')] || "其他错误"
          if (!statusCount[verdict]) statusCount[verdict] = 0
          statusCount[verdict]++;
        })
        setStatusCount(statusCount);
      }
    } catch {}
  }, [submission])
  return (<>
    {score && <Page ptr={false} infiniteLoader={false}>
      <StudentInfo />
      <div className="page-header">
        <div className="page-title">
          题目详情
        </div>
        <div className="page-subtitle">
          {problem.displayId} - {problem.problemTitle}
        </div>
      </div>
      <div className="page-body">
        {submission && <InfoCard data={[
          {title: '评测结果', value: statusMap[submission.status] || submission.status},
          {title: '分数', value: `${submission.score}`},
          {title: '运行时间', value: `${Math.round(submission.timeCost)} ms`},
          {title: '使用内存', value: `${Math.round(submission.memoryCost)} KB`},
          {title: '提交时间', value: moment(submission.submitTime).format('YYYY-MM-DD HH:mm:ss')},
        ]}/>}
        {statusCount && Object.keys(statusCount).length > 0 && <>
          <CellsTitle style={{marginTop: 0}}>测试点情况</CellsTitle>
          <Pie data={{
            labels: Object.keys(statusCount),
            datasets: [{
              data: Object.values(statusCount),
              backgroundColor: Object.keys(statusCount).map(key => {
                return colorMap[key] || '#7f7f7f'
              }),
            }],
          }}/>
        </>}
      </div>
    </Page>}
  </>)
}

import {Msg} from "react-weui"
import 'weui-icon/dist/filled/error.css'
const errorMap = {
  "QR Code Expired": "二维码已过期",
  "You've already bound the student": "你已经绑定过该学生了",
  "You haven't bound any student": "你还没有绑定过任何学生",
  "Server network error": "服务器网络错误，请稍后重试",
}
export function Error(props) {
  if (!props.error) return null
  let title = null
  let description = null
  if (props.error.message && (!props.error.response || !props.error.response.data)){
    if (errorMap[props.error.message.trim()]) title = errorMap[props.error.message.trim()]
    else {
      title = "未知错误，请尝试关闭页面重试"
      description = props.error.message.trim()
    }
  }else if (!props.error.response || !props.error.response.data) title = "网络错误"
  else if (errorMap[props.error.response.data.trim()]) title = errorMap[props.error.response.data.trim()]
  else if (props.error.message) {
    if (errorMap[props.error.message.trim()]) title = errorMap[props.error.message.trim()]
    else {
      title = "未知错误，请尝试关闭页面重试"
      description = props.error.message.trim()
    }
  }
  else {
    title = "未知错误，请尝试关闭页面重试"
    description = props.error.response.data.trim()
  }
  return (
    <Msg
      type="filled-error"
      title={title}
      description={description}
    />
  )
}
